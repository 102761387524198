<!-- This component can be used to create similar looking banners in the app -->
<!-- A Banner component usually takes the path to an image and a piece of text to be displayed over the image -->
<!-- Note: The image is darkened and the text is white. -->
<template>
    <div class="banner-component">
        <div class="uk-inline uk-section">
            <img :src=image alt="">
            <div class="uk-overlay uk-position-center">
                <h2 class="uk-text-center">{{ banner_text }}</h2>
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    name: "Banner",
    props: {
        image: String,
        banner_text: String
    }
}
</script>

<style lang="less" scoped>
    @import "../../assets/helpers.less";

    .uk-inline {
        width: 100%;
        height: 40vh;
        padding: 0;

        img {
            filter: brightness(50%);
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .uk-overlay {
            h2 {
                font-family: 'Lato';
                font-weight: 900;
                .fluid-font(2.5, 35, 320, 1920);
                color: white;
            }
        }
    }
</style>
<template>
    <div id="organizations-component">
        <div v-if="!loading" class="uk-section uk-section-large uk-padding uk-child-width-expand@m" uk-grid>
            <OrganizationCard
                class="uk-width-1-3@m"
                v-for="org in orgs" :key="org.idOrg"
                :org="org">

            </OrganizationCard>
        </div>

        <!-- Spinner to show while loading data -->
        <div v-if="loading" class="uk-section uk-section-large uk-flex uk-flex-center uk-flex-middle">
            <div uk-spinner="ratio: 5"></div>
        </div>
    </div>
</template>

<script>
import OrganizationCard from "./Reusables/OrganizationCard";

export default {
    name: 'Organizations',
    components: {
        OrganizationCard
    },
    data: function() {
        return {
            // Define data variables
            loading: true,
            orgs: []
        }
    },
    methods: {
        async loadOrganizations() {
            await this.$store.dispatch('loadOrganizations');
            this.loading = false;
            this.orgs = this.$store.state.orgs;
        }
    },
    mounted() {
        let modal = document.getElementById('asign-modal');
        if (modal) {
            modal.remove();
        }

        this.$store.dispatch('resetAll');
        this.loadOrganizations();
    }
}
</script>

<style lang="less" scoped>
    @import "../assets/helpers.less";

    .uk-section {
        background: white;
    }

    .uk-icon {
        color: @main-color;
    }
</style>
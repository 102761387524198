<template>
    <div class="organization-card-component">
        <div v-on:click="expandOrganization" class="uk-card uk-card-default uk-card-body uk-box-shadow-medium uk-box-shadow-hover-large">
            <h3 class="uk-card-title uk-text-center">{{ org.orgName }}</h3>
        </div>
    </div>    
</template>

<script>
export default {
    name: 'OrganizationCard',
    props: {
        org: Object
    },
    methods: {
        expandOrganization() {
            this.$store.commit('setOrganization', this.org);
            this.$router.push({name: 'OrganizationDetails'})
        }
    }
}
</script>

<style lang="less" scoped>
    @import "../../assets/helpers.less";

    .uk-card-default {
        background-color: white;
        cursor: pointer;

        h3 {
            font-family: 'Lato';
            .fluid-font(1.2, 15, 320, 1920);
        }
    }
</style>
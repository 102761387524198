<template>
  <div id="home-view">
    <header>
      <Navbar></Navbar>
      <Banner :image="banner_image" banner_text="Administrarea Organizatiilor tale"></Banner>
      <router-view></router-view>
    </header>
  </div>
</template>

<script>
  import Navbar from "@/components/Navbar";
  import Banner from "@/components/Reusables/Banner"

  export default {
    name: 'Home',
    components: {
      Navbar,
      Banner
    },
    data: function() {
      return {
        banner_image: require('@/assets/theme/banner/office-building.webp')
      }
    }
  }
</script>

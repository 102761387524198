<template>
    <div id="navbar-component">
       <!-- Stick the navbar on the top of the screen -->
       <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
            <!-- Defining navbar using uikit -->
            <nav id="cat-header" class="uk-navbar-container" uk-navbar>
                <!-- Left side of the navbar -->
                <div class="uk-navbar-left">
                    <ul class="uk-navbar-nav">
                        <li><a href="#offcanvas-navbar" uk-toggle>Meniu</a></li>
                    </ul>
                </div>

                <!-- The middle of the navbar: visible only on medium and large devices -->
                <div class="uk-navbar-center uk-visible@s">
                    <router-link to="/home" class="uk-navbar-item uk-logo">Matrix Rom OrgAdmin</router-link>
                </div>

                <!-- Moving the app title on the right on small devices -->
                <div class="uk-navbar-right uk-hidden@s">
                    <router-link to="/home" class="uk-navbar-item uk-logo">Matrix Rom OrgAdmin</router-link>
                </div>

                <!-- The right side of the navbar: visible only on medium and large devices -->
                <div class="uk-navbar-right uk-visible@s">
                    <ul class="uk-navbar-nav">
                        <li><a href="#">Profil</a></li>
                    </ul>
                </div>
            </nav>
       </div>

        <!-- Defining off-canvas navbar -->
        <div id="offcanvas-navbar" uk-offcanvas>
            <div class="uk-offcanvas-bar">
                <!-- Creating overlay with user picture -->
                <div class="uk-inline">
                    <img id="back-image" src="@/assets/theme/banner/banner.jpg" alt="Banner photo">
                    <div class="uk-overlay uk-position-top">
                        <img src="@/assets/theme/img/profile.png" alt="Profile photo">
                        <p>{{ email }}</p>
                    </div>
                </div>
                <ul class="uk-nav uk-nav-default uk-padding">
                    <li><router-link to="/home"><fa-icon class="icon uk-margin-right" icon="home"></fa-icon>Pagina Principala</router-link></li>
                    <hr class="uk-divider">
                    <li v-on:click="logout"><router-link to="/"><fa-icon class="icon uk-margin-right" icon="sign-out-alt"></fa-icon>Iesi din aplicatie</router-link></li>
                </ul>
            </div>
        </div>
    </div>    
</template>

<script>
import Axios from 'axios';
import Cookies from '../../node_modules/js-cookie/src/js.cookie';
import {SERVER_URL} from '@/.env.js';
import Cacher from '@/services/Cacher';
import { Notifier } from '@/services/Notifier';

export default {
    name: "Navbar",
    props: {
        email: {
            type: String,
            default: "some@email.com"
        }
    },
    methods: {
        /**
         * This method will attemt to delete the authorization cookie
         */
        logout() {
            // Empty the Vuex store
            this.$store.dispatch('resetAll');            

            // Call logout method
            Axios.post(
                SERVER_URL + '/org/api/logout',
                {
                    initiator_id: Cacher.getFromCache('userId'),
                    device_id: Cookies.get('uniqueId')
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + Cookies.get('X-XSRF-TOKEN')
                    }
                }
            )
            .then(response => {
                console.log(response.data.msg);
                Cookies.remove('X-XSRF-TOKEN');
                localStorage.clear();
                this.$router.replace('/');
            })
            .catch(reason => {
                console.log(reason);
                Notifier.notify('Nu am putut sa va deconectam. Mai incercati.', 'danger');
            });
        }
    }
}
</script>

<style lang="less" scoped>
    @import "../assets/helpers.less";

    .uk-logo, .uk-navbar-nav li a {
        font-family: 'Lato';
    }

    .uk-logo {
        .fluid-font(1.2, 7, 320, 1920);
    }

    .uk-navbar-nav li a {
        .fluid-font(1.2, 1.5, 320, 1920);
    }

    #back-image {
        filter: brightness(50%);
    }

    .uk-offcanvas-bar {
        background-color: white;
        padding: 0;

        .uk-overlay {
            p {
                font-family: 'Lato';
                .fluid-font(1, 1.2, 320, 1920);
                color: white;
            }

            img {
                width: 50px;
                height: 50px;
            }
        }

        .uk-nav {
            li a {
                font-family: 'Lato';
                font-weight: 700;
                .fluid-font(1, 1.7, 320, 1920);
                color: black;
            }

            .icon {
                color: @main-color !important;
            }

            .uk-divider {
                border-top: 1px solid black;
            }
        }
    }

</style>